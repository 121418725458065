<template>
    <b-card>
      <div class="d-flex justify-content-between align-items-center w-100 mb-1">
        <h3>التقييم</h3>
      </div>

      <div class="border py-1 px-1 rounded-lg mb-1 shadow-sm">
     
          <div class="d-flex justify-content-end">
            <b-button
              v-for="(item, i) in filterDate"
              :key="i"
              size="md"
              class="mx-1"
              variant="outline-success"
              pill
              @click="
                utils.status = item.status;
                $refs.estatesTable.refreshTable();
              "
              :class="utils.status == item.status ? 'bg-light-success' : ''"
              >{{ item.label }}</b-button
            >
          </div>
    
    </div>
  
      <data-table ref="estatesTable" :fields="fields"       :utils.sync="utils"
 ep="/evaluations">
        <template #cell(status)="row" >
          {{ row.item.status ? "راضي" :" غير راضي"}}
        </template>
      </data-table>
    </b-card>
  </template>
      <script>
  import {
    BCard,
    BButton,BCol,BRow} from "bootstrap-vue";
  import DataTable from "@/components/data-table/index";
  import { mapActions, mapGetters } from "vuex";
  export default {
    components: {
      BCard,
      DataTable,
      BButton,BCol,BRow
    },

    data: () => {
      return {
        filterDate: [
        { id: 1, label: "الكل", status: "" },
        { id: 2, label: "الراضين", status: "1" },
        { id: 3, label: "الغير راضين", status: "0" },
      ],
      utils: {
        status: "",
      },
        imageSlide: [],
        staticImages: [],
        fields: [
        { key: "status", label: "الحالة", sortable: true },
          { key: "phone_number", label: "رقم الموبايل", sortable: true },
          
        ],
        activModel:false,
        activModelservice:false,
        utils: {
          status: "",
        },
        filter: "",
      };
    },
    methods: {
      ...mapActions("settings/manageOffice", [
        "updateOffices",
        "createOffices",
        "deleteOffices",
        "updateServices"
      ]),
  
      onAdd() {
        this.$refs.estateModalOffice.init({});
        this.activModel = true;
      },
  
      onUpdateClicked(form) {
        var toUpdate = {
          id: form.id,
          office_no: form.office_no,
          room_no: form.room_no,
          floor: form.floor,
        
        };
        this.$refs.estateModalOffice.init(toUpdate);
        this.activModel = true;
      },
  
      onUpdateServiseClicked(form) {
        var toUpdate = {
          id: form.id,
          service_ids:form.services.map(item=>(item.id))
        };
        this.$refs.estateModalService.init(toUpdate);
        this.activModelservice = true;
      },
  
      onDelete(item) {
        this.$bvModal
          .msgBoxConfirm("هل أنت متأكد من حذف النشاط", {
            title: "تأكيد حذف النشاط",
            size: "sm",
            okVariant: "danger",
            okTitle: "تأكيد",
            cancelTitle: "إلغاء",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value)
              this.deleteOffices({ id: item.id }).then(() => {
                this.$refs.estatesTable.refreshTable();
              });
          });
      },
  
      onModalConfirmed(form) {
        if (form.id) {
          this.updateOffices({ id: form.id, data: form }).then(() => {
            this.$refs.estatesTable.refreshTable();
            this.activModel = false;
          });
        } else
          this.createOffices({ data: form  }).then(() => {
            this.$refs.estatesTable.refreshTable();
            this.activModel = false;
          });
      },
      onModalServicesConfirmed(form) {
        if (form.id) {
          this.updateServices({ id: form.id, data: form }).then(() => {
            this.$refs.estatesTable.refreshTable();
            this.activModelservice = false;
          });
        }
      },
    },
  
    computed: {
      ...mapGetters("settings/manageOffice", ["loading","services"]),
    },
  };
  </script>
    <style lang="scss" >
  .top-m {
    padding-top: 7px;
  }
  </style>